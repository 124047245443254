import React, { Props,Component, Fragment } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';


import Header from './Common/Header';
import Footer from './Common/Footer';

import { getSearchSuggestions } from '../Actions/WebsiteActions';
import {Link} from 'react-router-dom'
import {Redirect} from 'react-router-dom'


import { GetSiteContent } from '../Actions/WebsiteActions';
import { SnackBar } from '../Actions/CommonActions';
import Alert from './Common/Alert';
import {Helmet} from "react-helmet";

const mapStateToProps = (state) => {
    return {
		loading : state.Common.loading,
		loggedIn : state.Auth.loggedIn,
		aboutus : state.Website.site_aboutus,
		aboutus_meta_title:state.Website.aboutus_meta_title,
    	aboutus_meta_description:state.Website.aboutus_meta_description,
    }
  }

  const mapDispatchToProps = dispatch => ({
	GetSiteContent:()=>dispatch(GetSiteContent())
  });
class About extends Component{

	constructor(props){
		super(props);

		this.state = {
            username: "",
            password:"",
            loggedIn:false,
            loading:false,
		};

		this.props.GetSiteContent();
	}

	componentDidMount() {
        window.scrollTo(0,0)
	  }

	handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
	  };




	componentDidMount() {
        window.scrollTo(0,0)
      }


    render(){
        return <Fragment>
                    <Header home={false}/>
					<Alert/>
					<Helmet>
						<meta charSet="utf-8" />
						<title>{this.props.aboutus_meta_title}</title>
						<meta name="description" content={this.props.aboutus_meta_description} />
						<meta property="og:title" content={this.props.aboutus_meta_title} />
						<meta property="og:type" content="article" />
						<meta property="og:url" content="https://aktivitar.com/about" />
					</Helmet>
                    <section className="bedcmp-section">
	<div className="container">
		<div className="row">
			<div className="col l12">
				<div className="hed-bed">
					<div className="bred-cmp">
						<a href="">Home</a> / <span>About Us</span>
					</div>
					<h1>About us</h1>
				</div>
			</div>
		</div>
	</div>
</section>
<section>
	<div class="container">
		<div class="row">
			<div class="col l7 m7 s12">
				<div class="abou-cont">
					<p>{this.props.aboutus}</p>

				</div>
			</div>
			<div class="col l5 m5 s12">
				<div class="abot-img">
					<img src="./img/about.png" />
				</div>
			</div>
		</div>
	</div>
</section>
<section class="abou-gry">
	<div class="container">
		<div class="row">
			<div class="col l7">
				<div class="row">
					<div class="col l6 s12">
						<div class="cont-abou">
							<h5>Connect with Us!</h5>
							<p>Want to know more? Join us </p>
						</div>
					</div>
					<div class="col l6 s12">
						<div class="cont-abou">
							<ul>
								<li><a className="fb" href="https://www.facebook.com/aktivitar" target="_blank"></a></li>
								<li><a className="you" ></a></li>
								<li><a className="pin" href="https://www.instagram.com/aktivitar/" target="_blank"></a></li>

							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<Footer/>

                </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(About);
